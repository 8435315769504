import React, { useLayoutEffect } from "react";
import { Router } from "@reach/router";
import {
  HomePage,
  ContactUsPage,
  DynamicPage,
  AboutUsPage,
  homeTemplatesVersionVariantTypes,
  aboutUsTemplatesVersionVariantsTypes,
  contactUsTemplatesVersionVariantsTypes,
  dynamicTemplatesVersionVariantsTypes,
  colorsVariantsTypes,
  fontsFamiliesTypes,
  colorsTypes,
  loginVariantTypes,
} from "@homecu/blossom-websites-core-package";

import templateVariantSettings from "config";

// import { HOMEPAGE_DATA_MODEL } from "data/cms-models";

function IndexPage() {
  //  Propiedades comunes entre los Pages/Paginas
  const headerLoginButtonStyles = {
    variant: loginVariantTypes.FORM,
  };
  const commonProps = {
    headerLoginButtonStyles,
    floatHeader: true,
    // Configuracion inicial de la plantilla
    pageTitleProps: {
      TitleColor: "#0C1C4C",
      SubtitleColor: "#0C1C4C",
    },
    headerMobileNoFilterLogo: true,
    headerMobileNoFilterMenuIcon: true,
    config: templateVariantSettings,
    // Habilitar o Desabilitar background de tipo sombra al abrir el menu de navegacion
    backgroundScreenHeader: true,
    //  Configuracion personalizada del Navigation Bar (Menu de Navegacion)
    navigationMenuOptions: {
      // Cambiar la altura del sub-menu de navegacion
      dropdownSeparationFromBottom: 53,
      fixed: true,
      dropdownHoverBackground: colorsVariantsTypes.TRANSPARENT,
      backgroundScreenActive: false,
    },
    // Personalizar Footer
    footerOptions: {},
  };
  const footerFirstLogoOnClick = () => {
    if (!window || !window.document || !document) {
      setTimeout(footerFirstLogoOnClick, 350);

      return;
    }

    const footerFirstLogo =
      document.querySelector(".FOOTER__BUSINESS_LOGO:first-child") ||
      document.querySelector(
        "footer > div:first-child > *:nth-child(2) > *:nth-child(3) > *:nth-child(1) > *:nth-child(1)",
      );

    const footerSecondLogo =
      document.querySelector(".FOOTER__BUSINESS_LOGO:nth-child(2)") ||
      document.querySelector(
        "footer > div:first-child > *:nth-child(2) > *:nth-child(3) > *:nth-child(1) > *:nth-child(2)",
      );
    const icons = document.querySelectorAll(".tw-inline-block.tw-mr-3");

    if (!footerFirstLogo) {
      setTimeout(footerFirstLogoOnClick, 350);

      return;
    }

    footerFirstLogo.setAttribute("alt", "NCUA logo");
    footerSecondLogo.setAttribute("alt", "Equal Housing logo");

    icons.forEach(icon => icon.setAttribute("alt", ""));
    const newA = document.createElement("a");
    newA.setAttribute("href", "https://www.ncua.gov");
    newA.style.cssText = "position: absolute; width: 200px; height: 70px";
    newA.classList.add("FOOTER__BUSINESS_LOGO_LINK");
    footerFirstLogo.parentElement.appendChild(newA);
  };

  useLayoutEffect(() => {
    setTimeout(footerFirstLogoOnClick, 3000);
  }, []);
  return (
    <Router>
      <HomePage
        path="/"
        variant={homeTemplatesVersionVariantTypes.HOME_TEMPLATE_V9}
        {...commonProps}
        // Forzar el uso de un Template
        // forceCustomVariant
        // variant={homeTemplatesVersionVariantTypes.HOME_TEMPLATE_V3}
        // Personalizar Hero
        heroProps={{
          //  Title Font Size
          titleSize: colorsVariantsTypes.WHITE,
          //  Title Font Family
          titleFontFamily: fontsFamiliesTypes.SECONDARY,
          //  Description Font Size
          subTitleSize: colorsVariantsTypes.WHITE,
          //  Description Font Family
          subTitleFontFamily: fontsFamiliesTypes.SECONDARY,
          //  Custom Gradient
          gradient: `linear-gradient(106.01deg, rgba(0, 0, 0, 0.5) 44.02%, rgba(0, 0, 0, 0.085) 74.63%, rgba(0, 0, 0, 0) 100%)`,
          //  Custom Button
          buttonStylesProps: {
            grayscale: true,
            withoutBorderRadius: true,
            grayscaleAndOFilledTextColor: colorsTypes.PRIMARY_DARK,
            margin: "0 auto",
            height: "auto",
            maxWidth: 640,
          },
        }}
        // Personalizar Lista de Productos e items
        productsProps={{}}
        // Personalizar Feature
        featureProps={
          {
            // variant: "V2",
          }
        }
        // Personalizar Feature Full
        featureFullProps={{}}
        // Personalizar Callout
        calloutProps={{}}
        // Personalizar Callout Mobile
        calloutMobileProps={{}}
        // Personalizar Callout Compressed
        calloutCompressedProps={{}}
      />

      <AboutUsPage
        path="/about-us"
        variant={aboutUsTemplatesVersionVariantsTypes.ABOUT_US_TEMPLATE_V2}
        {...commonProps}
        // Personalizar Page Header
        pageHeaderProps={{}}
        // Personalizar Body Text
        bodyTextProps={{}}
        // Personalizar Callout Text
        calloutTextProps={{}}
        // Personalizar Callout Button
        calloutButtonProps={{}}
      />

      <ContactUsPage
        path="/contact-us"
        variant={contactUsTemplatesVersionVariantsTypes.CONTACT_US_TEMPLATE_V10}
        {...commonProps}
        // Personalizar Submit Button
        submitButton={{}}
        // Personalizar Header
        pageHeaderProps={{}}
        // Personalizar Page Title
        pageTitleProps={{}}
        // Personalizar Contact
        contactProps={{}}
        // Personalizar Branch
        branchProps={{}}
        // Personalizar  Callout
        calloutProps={{}}
        // Personalizar  Callout Mobile With Gradient
        calloutMobileWithGradientPros={{}}
      />

      <DynamicPage
        path="/:id"
        variant={dynamicTemplatesVersionVariantsTypes.DYNAMIC_TEMPLATE_V9}
        {...commonProps}
        // Personalizar Custom Page
        customPageProps={{}}
        // Personalizar Hero
        heroProps={{}}
        // Personalizar Lista de Productos
        productsProps={{}}
        // Personalizar Feature Full
        featureFullProps={{}}
        // Personalizar Feature
        featureProps={{}}
        // Personalizar Callout
        calloutProps={{}}
        // Personalizar Callout Mobile
        calloutMobileProps={{}}
        // Personalizar Callout Compressed
        calloutCompressedProps={{}}
      />
    </Router>
  );
}

export default IndexPage;
